<template>
  <LayoutWithoutMap>
    <template #title> Pollution </template>
    <template #body>
      <div class="w-full bg-promy-gray-50">
        <div
          v-if="charts.length > 0"
          class="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:!grid-cols-1 gap-7"
        >
          <SemicircleChart
            classesContainerChart="px-14 sm-ws:px-6 lg:px-0"
            v-for="(chart, index) in charts"
            :chartId="`Semi-circle-chart-#${index}`"
            :title="chart.title"
            :score="chart.score"
            :states="chartStates(chart.inverted)"
            :inverted="chart.inverted"
            :key="index"
          />
        </div>
        <empty-state
          v-else
          class="h-4/5 mt-7 bg-transparent"
          :message="'Ces informations ne sont pas disponibles pour cette adresse'"
          :main_icon="'bot1'"
          classes_icon="!w-1/4 !h-1/4 sm:!w-48 sm:!h-48"
        />
      </div>
    </template>
  </LayoutWithoutMap>
</template>
<script>
import { mapGetters } from 'vuex'
import LayoutWithoutMap from './LayoutWithoutMap.vue'
export default {
  components: {
    LayoutWithoutMap,
  },
  computed: {
    ...mapGetters({
      poi: 'address/getPOI',
    }),
  },
  created: function () {
    const pollutions = this.poi.data.polution

    Object.keys(pollutions).forEach((pollutionType) => {
      let pollutionTitle = ''

      switch (pollutionType) {
        case 'pollution_air':
          pollutionTitle = "Pollution de l'air"
          break
        case 'pollution_electromagnetique':
          pollutionTitle = 'Pollution électromagnétique'
          break
        case 'saturation_population':
          pollutionTitle = 'Densité démographique'
          break
        case 'saturation_vehicules':
          pollutionTitle = 'Circulation'
          break
        default:
          pollutionTitle = pollutionType.split('_').join(' ')
      }
      pollutionTitle =
        pollutionTitle.charAt(0).toUpperCase() + pollutionTitle.slice(1)
      this.charts.push({
        title: pollutionTitle,
        score: pollutions[pollutionType].score,
        inverted: true,
      })
    })
  },
  data() {
    return {
      charts: [],
      chartStates: (inverted) => [
        {
          label: 'Modéré',
          min: 30,
          max: 70,
          condition: (score, min, max) => score > min && score < max,
        },
        {
          label: 'Bien',
          min: inverted ? 70 : 0,
          max: inverted ? 100 : 30,
          condition: (score, min, max) =>
            inverted ? score >= min : score <= max,
        },
        {
          label: 'Mauvais',
          min: inverted ? 0 : 70,
          max: inverted ? 30 : 100,
          condition: (score, min, max) =>
            inverted ? score <= max : score >= min,
        },
      ],
    }
  },
  methods: {},
}
</script>
